import store from '@/store/index'
import { Toast } from 'vant';
import api from '../request/api'
import { isPrinterSupported, connectAuto, checkTagConnect, connectAutoLabel, getBleConnectStatus, connectAutoLabelBlue } from './outPrint';
import { labelPrintLodopTemp, printLodopTemp } from './win-print';
const formatNumber_y = n => {
  n = n.toString()
  return n[1] ? n : '0' + n
}

export function formatTimeWitchLine(date) {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  return [year, month, day].map(formatNumber_y).join('-')
}

export function formatTime(date, fmt) {
	let o = { 
		"M+" : date.getMonth()+1,                 //月份 
		"d+" : date.getDate(),                    //日 
		"h+" : date.getHours(),                   //小时 
		"m+" : date.getMinutes(),                 //分 
		"s+" : date.getSeconds(),                 //秒 
		"q+" : Math.floor((date.getMonth()+3)/3), //季度 
		"S"  : date.getMilliseconds()             //毫秒 
	}; 
	if(/(y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, (date.getFullYear()+"").substr(4 - RegExp.$1.length)); 
	}
	 for(var k in o) {
		if(new RegExp("("+ k +")").test(fmt)){
			 fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
		}
	}
	return fmt; 
}

export function datetimePickerFormatter(type, val) {
  if (type === 'year') {
    return val + '年';
  }
  if (type === 'month') {
    return val + '月';
  }
  if (type === 'day') {
    return val + '日';
  }
  if (type === 'hour') {
    return val + '时'
  }
  if (type === 'minute') {
    return val + '分'
  }
  return val;
}

export function getPayWayFromCode(code) {
  let way = '';
  let pay_code = code.substr(0, 2);
  // console.log(pay_code);
  // 微信付款码规则：18位纯数字，前缀以10、11、12、13、14、15开头
  if (10 <= pay_code && pay_code <= 15) {
    way = '1';
  } else if (25 <= pay_code && pay_code <= 30) { // 支付宝付款码规则：25~30开头的长度为16~24位的数字，实际字符串长度以开发者获取的付款码长度为准
    way = '2';
  } else if (pay_code > 50) {
    way = '4';
  }
  return way;
}

// 获取当前指定前几天的日期
export function getBeforeDate(days){
  const now = new Date().getTime();
  const ago = now - 86400000 * days;//一天的毫秒数为86400000
  let agoData = new Date(ago);
  return agoData;
}

// 更新对应系统打印机信息
export function updatePrinterInfo(data, type, printer_address) {
  console.log('updatePrinterInfo', data, type, printer_address);
  let params = data;
  params['printer_address'] = printer_address;
  params['type'] = type; // 类型 1，前台打印机  2，后厨打印机 3标签打印机

  api.dt_editPrinter(params).then(res => {
    console.log('修改editPrinter', res);
  })
}

// 判断是否是在APP内打开，dzbdcAgent是在config.xml中进行配置，具体查看cordova相关配置
export async function _isInApp(name) {
  console.log(name == 'index' ? '在首页的！！！！！！' : 'APP！！！！！！');
  let device = '';
  console.log('navigator', navigator);
  let flag_android = navigator.userAgent.match(/dzbMCashierAgent/g) // cordova安卓打包,dzbdcAgent是在config.xml中进行配置
  // let flag_android = 1 // cordova安卓打包,dzbdcAgent是在config.xml中进行配置
  console.log('flag_android:', flag_android);
  let flag_ios = navigator.userAgent.match(/dzbMcashierIOS/g) // cordova IOS打包,dzbdcIOS是在config.xml中进行配置
  console.log('flag_ios:', flag_ios);
  if (flag_android) {
    // console.log('环境--Android移动端');
    const supported = await isPrinterSupported(name); // 检查是否支持内置打印，如果code=1，就定义为mt_android（美团收银机）
    // console.log('supported----', supported);
    if (supported && supported.code == 1) {
      device = 'mt_android';
    } else {
      device = 'android';
    }
  } else if (flag_ios) {
    // console.log('环境--IOS移动端');
    device = 'ios';
  } else {
    device = 'windows';
  }
  // device = 'android';
  return device
}

export function checkPrintType() {
  // if (!store.state.deviceMac || store.state.deviceMac == '') {
  //   return;
  // }
  return new Promise((resolve, reject) => {
    api.dt_printerList({
      device_mac: store.state.deviceMac
    }).then(async res => {
      if (res.code == 1) {
        let front_printer = res.data.front_printer || [];
        const index = front_printer.findIndex(
          (e) => e.bind_status == 1
        );
        // console.log('前台打印机', front_printer[index]);
        if (index > -1) {
          store.commit('updateQtPrinter', front_printer[index]);
          let print_type = getPrinterType(front_printer[index].printer_type.toString(), 1)
          localStorage.setItem('printType', print_type)
          localStorage.setItem('qiantaiPrintSize', front_printer[index].size)
          localStorage.setItem('printer_id', front_printer[index].id || 0);
          if (print_type == 4) { // 网络打印机
            localStorage.setItem('qiantaiPrintIp', front_printer[index].ip)
          }

          if (print_type == '6') {
            localStorage.setItem('qtDeviceSN', front_printer[index].cloud_sn)
            localStorage.setItem('qtDeviceKey', front_printer[index].cloud_key)
          }

          if (print_type == '11') {
            localStorage.setItem('qtDeviceSN', front_printer[index].cloud_sn)
          }

          if (print_type == 1 || print_type == 3) {
            localStorage.setItem('printer_name', front_printer[index].printer_name)
            localStorage.setItem('printer_address', front_printer[index].printer_address)
            connectAuto(front_printer[index]);
          }
        } else {
          // console.log('未设置前台打印机');
          localStorage.removeItem('printType')
          localStorage.removeItem('qiantaiPrintIp')
          localStorage.removeItem('qiantaiPrintSize')
          localStorage.removeItem('printer_name')
          localStorage.removeItem('printer_address')
          localStorage.removeItem('printer_id');
          localStorage.removeItem('qtDeviceSN');
          localStorage.removeItem('qtDeviceKey');
        }

        let tag_printers = res.data.tag_printer || [];
        store.commit('updateTagPrinter', tag_printers);
        checkLabelPrint();
        // console.log('checkPrintType---===', type);
        resolve(res);
      } else {
        // Toast('未设置打印机');
        localStorage.removeItem('printType')
        localStorage.removeItem('qiantaiPrintIp')
        localStorage.removeItem('qiantaiPrintSize')
        localStorage.removeItem('printer_name')
        localStorage.removeItem('printer_address')
        localStorage.removeItem('printer_id');
        localStorage.removeItem('qtDeviceSN');
        localStorage.removeItem('qtDeviceKey');
        resolve(res);
      }
    }).catch(() => {
      resolve();
    })
  })
}

/**
 * 由于前端与后台的打印机类型值不同，进行转换
 * @param type 打印机类型
 * @param action 1后台转前端，2前端转后台
 * @param printer 设置的打印机类型，1前台打印机，2后厨打印机，3标签打印机
 */
export function getPrinterType(type, action, printer) {
  type = type.toString();
  if (action == 2) { // 前端转后台
    // 前端定义的打印机类型 2：SUNMI打印机  4：网络打印机  3：蓝牙打印机 1：USB打印机 5：驱动打印机   6：云打印机 
    // 7：USB打印机-标签打印机安卓 8：驱动打印机-标签打印机Windows 9：蓝牙打印机-标签打印机IOS 10：美团内置打印机
    // 11：佳博云打印机
    // 传给后台的打印机类型 1，summi打印机  2，网络打印机  3，蓝牙打印机 4，USB打印机 5，电子秤打印机，6，云打印机 7，佳博云打印机
    let tp = '1';
    switch (type) {
      case '2':
        tp = '1';
        break;
      case '4':
        tp = '2';
        break;
      case '1':
        tp = '4';
        break;
      case '7':
        tp = '4';
        break;
      case '8':
        tp = '5';
        break;
      case '9':
        tp = '3';
        break;
      case '11':
        tp = '7';
        break;
      default:
        tp = type;
        break;
    }
    return tp;
  } else { // 后台转前端
    // 传给后台的打印机类型 1，summi打印机  2，网络打印机  3，蓝牙打印机 4，USB打印机 5，电子秤打印机，6 云打印机 7，佳博云打印机
    // 前端定义的打印机类型 2：SUNMI打印机  4：网络打印机  3蓝牙打印机 1USB打印机 5：驱动打印机   6：云打印机 7：USB打印机-标签打印机安卓 
    // 8：驱动打印机-标签打印机Windows 9：蓝牙打印机-标签打印机IOS 10：美团内置打印机 11：佳博云打印机
    let tp = '2';
    switch (type) {
      case '1':
        tp = '2';
        break;
      case '2':
        tp = '4';
        break;
      case '4':
        if (printer == 3) {
          tp = '7'
        } else {
          tp = '1';
        }
        break;
      case '3':
        if (printer == 3) {
          tp = '9';
        } else {
          tp = '3';
        }
        break;
      case '5':
        if (printer == 3) {
          tp = '8'
        } else {
          tp = '5';
        }
        break;
      case '7':
        tp = '11';
        break;
      default:
        tp = type;
        break;
    }
    return tp;
  }
}

export function netPrintSend(data) {
  // console.log('////////////////////////', data);
  // // console.log('网络打印机打印信息：', data.type, JSON.stringify(data));
  let p_data = new FormData();
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const element = data[key];
      if (key == 'data' || (data.type == 2 && (key == 'member' || key == 'member_type'))) {
        p_data.append(key, JSON.stringify(element));
      } else {
        p_data.append(key, element);
      }
    }
  }
  return new Promise((resolve, reject) => {
    // resolve();
    api.netPrint(p_data).then(res_ => {
      if (res_.code == 1) {
        resolve(res_);
      } else {
        reject(res_);
      }
    }).catch(() => {
      Toast('打印失败，未连接到打印机')
      reject();
    });
  })
}

export function winPrintSend(data) {
  console.log('打印机打印信息：', data.type, JSON.stringify(data));
  return new Promise((resolve, reject) => {
    printLodopTemp(data).then(res => {
      resolve();
    });
  })
}

export function winPrintTagSend(data, sizeType) {
  console.log('标签打印机打印信息：', JSON.stringify(data));
  const tag_list = store.state.tagPrinter;
  return new Promise((resolve, reject) => {
    for (let j = 0; j < data.good_list.length; j++) {
      const element = data.good_list[j];
      const p_num = element.print_num || 1;
      for (let i = 0; i < p_num; i++) {
        element['sizeType'] = sizeType;
        element['type'] = 5;
        element['printer_name'] = tag_list[0].printer_name || '';
        labelPrintLodopTemp(element).then(res => {
          resolve();
        });
      }
    }
  })
}

export function randomString(e, t) {  
  e = e || 32;
  // console.log(t);
  let a = t.length,
    n = "";
  for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
  return n
}

// 计重单位换算 电子秤计重单位是千克，根据传入的单位相应换算
export function weightUnitConversion(weight, unit) {
  let w = 0; // 换算后的计重，X${unit}
  if (unit == '千克' || unit.toUpperCase() == 'KG') {
    w = weight;
  } else if (unit == '克' || unit.toUpperCase() == 'G') {
    w = weight * 1000;
  } else if (unit == '斤' || unit.toUpperCase() == 'CA') {
    w = parseFloat(weight * 100 * 2 / 100).toFixed(3);
  }
  return w;
}

// 价格单位换算
export function priceUnitConversion(price, unit) {
  let p = 0; // 换算后的价格，￥X/千克
  if (unit == '千克' || unit.toUpperCase() == 'KG') {
    p = price;
  } else if (unit == '克' || unit.toUpperCase() == 'G') {
    p = price * 1000;
  } else if (unit == '斤' || unit.toUpperCase() == 'CA') {
    p = price * 100 * 2 / 100;
  }
  return p;
}

export async function checkLabelPrint(str) {
  const tag_printer_list = store.state.tagPrinter;
  console.log('checkLabelPrint tag_printer_list', tag_printer_list, str);
  setTimeout(async () => {
    if (tag_printer_list.length > 0) {
      const tag_printer = tag_printer_list[0];
      const printer_type = getPrinterType(tag_printer.printer_type.toString(), 1, 3);
      console.log('checkLabelPrint printer_type', printer_type);
      if (printer_type == 7) {
        const tagRes = await checkTagConnect();
        // console.log('tagRes', tagRes);
        if (tagRes.code != 1) {
          connectAutoLabel('usb', tag_printer); // 目前安卓只有USB
        }
      } else if (printer_type == 12) { // 蓝牙
        const tagRes = await checkTagConnect();
        console.log('tagRes', tagRes);
        if (tagRes.code != 1) {
          connectAutoLabel('bluetooth', tag_printer);
        }
      } else if (printer_type == 9) { // ios蓝牙
        const tagRes = await getBleConnectStatus();
        console.log('tagRes, iOS', tagRes);
        if (tagRes.code != 1) {
          connectAutoLabelBlue(tag_printer); // iOS，蓝牙
        }
      }
    }
  }, 500);
}