<template>
    <div id="app">
        <navtar v-if="is_show_navtar" />
        <div class="appCon" :class="{'appcon-content': is_show_navtar}">
            <router-view/>
        </div>

        <div id="printArea"></div>
    </div>
</template>

<script>
import { checkPrintType, randomString, _isInApp } from './utils/util';
import navtar from './views/component/navtar.vue';
import { getListener, deviceInfo, getScaleData, isScaleSupported, deviceInfoForUUID, getScaleDateHcd, isPrinterSupported, getScaleDateSm, connectSunMiScaleService, sunMiScaleDataInfo, initHCDScannerDecode, getScaleDataRd } from './utils/outPrint'
import { getThisWeightDetail } from './utils/win-print';
export default {
    name: 'App',
    components: {
        navtar,
    },
    computed: {
        is_show_navtar() {
            return this.$store.state.is_show_navtar;
            // return false;
        },
        platform_name() {
            return localStorage.nowDevice;
        },
        scale_type() {
            return localStorage.scale_type;
        },
    },
    async mounted() {
        window.addEventListener('load', this.onPageRefresh);
        const nowDevice = await _isInApp();
        console.log('nowDevice', nowDevice);
        localStorage.setItem('nowDevice', nowDevice);
        this.setUUID();
        if (this.platform_name == 'windows') {
            // await this.$device.getLocalIP();
            getThisWeightDetail();
            checkPrintType();
        } else if (this.platform_name == 'android') {
            document.addEventListener("deviceready", this.handleReady, false);
        }
        else if (this.isApp == 'mt_android') {
            this.mtMounted();
        } else if (this.isApp == 'ios') {
            document.addEventListener("deviceready", this.handleReadyIos, false);
        }
        if (!localStorage.token) {
            return;
        }
        this.$store.dispatch('getStoreInfo');
    },
    methods: {
        async handleReadyIos() {
            if (localStorage.token) {
                await checkPrintType();
            }
        },
        mtMounted() {
            document.addEventListener("deviceready", this.handleReadyMt, false);
            setTimeout(async () => {
                const scaleSupported = await isScaleSupported();
                this.$store.commit('updateMtScale', scaleSupported);
                console.log('scaleSupported', scaleSupported);
                console.log('this.$store.state.mtScale', this.$store.state.mtScale);
            }, 1500);
        },
        async handleReadyMt() {
            deviceInfo();
            if (localStorage.token) {
                getListener();
                await checkPrintType();
            }
        },
        async onDeviceReady() {
            const that = this;
            // console.log('onDeviceReady device', device);
            // localStorage.setItem("device_code", device.uuid);
            deviceInfoForUUID();
            // const supported = await isPrinterSupported('onDeviceReady'); // 检查是否支持内置打印，如果code=1，就定义为mt_android（美团收银机）
            // console.log('onDeviceReady  supported--------', JSON.stringify(supported));
        },
        async setUUID() {
            if (this.platform_name != 'windows') {
                localStorage.removeItem('device_code');
                document.addEventListener("deviceready", this.onDeviceReady, false);
            } else {
                if (!localStorage.device_code) {
                    // localStorage.setItem("device_code", 'lspc' + randomString(10, 'abcdefhijkmnprstwxyz123456789'));
                    await this.$device.getLocalIP();
                    const code = this.$store.state.deviceMac || 'lspc' + randomString(10, 'abcdefhijkmnprstwxyz123456789');
                    localStorage.setItem("device_code", code);
                }
            }
        },
        async handleReady() {
            const supported = await isPrinterSupported('handleReady'); // 检查是否支持内置打印，如果code=1，就定义为mt_android（美团收银机）
            console.log('handleReady  supported--------', JSON.stringify(supported));
            if (supported && supported.code == 1) {
                localStorage.setItem('nowDevice', 'mt_android');
                this.mtMounted();
                return;
            }
            deviceInfo();
            if (localStorage.token) {
                getListener();
                await checkPrintType();
            }
            if (this.scale_type == '1') {
                getScaleDateHcd();
            } else if (this.scale_type == '4') {
                getScaleData();
            } else if (this.scale_type == '3') {
                setTimeout(async () => {
                    const scaleSupported = await isScaleSupported();
                    this.$store.commit('updateMtScale', scaleSupported);
                    console.log('scaleSupported', scaleSupported);
                    console.log('this.$store.state.mtScale', this.$store.state.mtScale);
                }, 1500);
            } else if (this.scale_type == '2') {
                await connectSunMiScaleService();
                setTimeout(() => {
                    sunMiScaleDataInfo();
                }, 500);
                getScaleDateSm();
            } else if (this.scale_type == '5') {
                getScaleDataRd();
            }
        },
        onPageRefresh() {
            this.$store.dispatch("setNowTimeStr");
            if (localStorage.token) {
                this.$store.commit('updateIsShowNavtar', true);
            } else {
                this.$store.commit('updateIsShowNavtar', false);
            }
        }
    },
    destroyed() {
        initHCDScannerDecode('stophcdscan');
    },
}
</script>


<style lang="less">
#printArea{
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(-300%);
    z-index: -1000;
}
@color: #1588F5;
.appCon {
    position: relative;
    width: 100%;
    height: 100%;
    .popup-picker-custom {
        width: 42%;
        left: 29%;
    }
}
.appcon-content {
    margin-left: 1.5rem;
    margin-top: 10rem;
    height: calc(100vh - 10rem) !important;
    width: 178.5rem !important;
}
//#app {
//    background: url("./static/line.png");
//    background-size: 59%;
//    background-repeat: no-repeat;
//    background-position: center;
//}
#app {
    // -webkit-font-smoothing: antialiased;
    // -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    width: 100%;
    height: 100%;
    overflow: hidden;
    //transform: translate(0.1rem, -20.5rem) scale(0.543);
    display: flex;
    background: #f4f4f4;
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}

/* 自适应 */
html {
    /* font-size: calc(100vw / 192); */
}

html .van-toast {
    width: auto;
    //top: 35%;
}

html .van-toast--text {
    padding: 1em;
    font-size: 1.3em;
}

html .van-toast__text {
    font-size: 1.6em;
}

html .van-dialog {
    width: 26em;
    height: 13em;
    //top: 35%;
}

html .van-dialog .van-dialog__header {
    font-size: 1.6em;
    line-height: 2em;
}

html .van-dialog .van-dialog__message,
html .van-dialog .van-button__content {
    font-size: 1.3em;
    line-height: 2em;
}
.van-popup--center { /* 暂时上移一点van-popup，防止表单被软键盘挡住 */
    top: 40% !important;
}
</style>
