import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';

Vue.use(VueRouter)
//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/common/login'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/forgetPassword',
    name: 'forgetPassword',
    component: () => import('@/views/common/forgetPassword'),
    meta: {
      title: '忘记密码'
    }
  },
  {
    path: '/voucher',
    name: 'voucher',
    component: () => import('@/views/common/voucher'),
    meta: {
      title: '小票'
    }
  },
  {
    path: '/successionVoucher',
    name: 'successionVoucher',
    component: () => import('@/views/common/successionVoucher'),
    meta: {
      title: '交接班小票'
    }
  },

  {
    path: '/',
    name: 'index',
    component: () => import('@/views/home/index'),
    meta: {
      title: '首页'
    }
  },
  {
    path: '/addProduct',
    name: 'addProduct',
    component: () => import('@/views/home/addProduct'),
    meta: {
      title: '新增商品'
    }
  },
  {
    path: '/areCancell',
    name: 'areCancell',
    component: () => import('@/views/home/areCancell'),
    meta: {
      title: '挂单'
    }
  },
  {
    path: '/settlement',
    name: 'settlement',
    component: () => import('@/views/home/settlement'),
    meta: {
      title: '结算'
    }
  },
  {
    path: '/toTakeOrders',
    name: 'toTakeOrders',
    component: () => import('@/views/home/toTakeOrders'),
    meta: {
      title: '取单'
    }
  },
  {
    path: '/manageProducts',
    name: 'manageProducts',
    component: () => import('@/views/home/manageProducts'),
    meta: {
      title: '管理商品'
    }
  },
  {
    path: '/purchase',
    name: 'purchase',
    component: () => import('@/views/home/purchase'),
    meta: {
      title: '退货'
    }
  },
  {
    path: '/financialControl',
    name: 'financialControl',
    component: () => import('@/views/home/financialControl'),
    meta: {
      title: '财务管理'
    }
  },
  {
    path: '/inventoryDe',
    name: 'inventoryDe',
    component: () => import('@/views/home/inventoryDe'),
    meta: {
      title: '库存明细'
    }
  },
  {
    path: '/employee',
    name: 'employee',
    component: () => import('@/views/home/employee'),
    meta: {
      title: '员工管理'
    }
  },

  {
    path: '/accountManage',
    name: 'accountManage',
    component: () => import('@/views/setting/accountManage'),
    meta: {
      title: '账号管理与系统信息'
    }
  },
  {
    path: '/afterSaleManagement',
    name: 'afterSaleManagement',
    component: () => import('@/views/setting/afterSaleManagement'),
    meta: {
      title: '售后管理'
    }
  },
  {
    path: '/orderManage',
    name: 'orderManage',
    component: () => import('@/views/setting/orderManage'),
    meta: {
      title: '订单管理'
    }
  },
  {
    path: '/returnTheOrder',
    name: 'returnTheOrder',
    component: () => import('@/views/setting/returnTheOrder'),
    meta: {
      title: '退货订单'
    }
  },
  {
    path: '/succession',
    name: 'succession',
    component: () => import('@/views/setting/succession'),
    meta: {
      title: '交接班'
    }
  },
  {
    path: '/progress',
    name: 'progress',
    component: () => import('@/views/setting/progress'),
    meta: {
      title: '交班记录'
    }
  },
  {
    path: '/sellingGoodsList',
    name: 'sellingGoodsList',
    component: () => import('@/views/setting/sellingGoodsList'),
    meta: {
      title: '销售商品列表'
    }
  },
  {
    path: '/featureSet',
    name: 'featureSet',
    component: () => import('@/views/setting/featureSet'),
    meta: {
      title: '功能设置'
    }
  },
  {
    path: '/cashierReceiptsMachineSettings',
    name: 'cashierReceiptsMachineSettings',
    component: () => import('@/views/setting/cashierReceiptsMachineSettings'),
    meta: {
      title: '收银小票机设置'
    }
  },
  {
    path: '/cashierInformationSet',
    name: 'cashierInformationSet',
    component: () => import('@/views/setting/cashierInformationSet'),
    meta: {
      title: '收银信息设置'
    }
  },
  {
    path: '/anExternalDevice',
    name: 'anExternalDevice',
    component: () => import('@/views/setting/anExternalDevice'),
    meta: {
      title: '外部设备'
    }
  },
  {
    path: '/ShopCategory',
    name: 'ShopCategory',
    component: () => import('@/views/home/ShopCategory'),
    meta: {
      title: '分类管理'
    }
  },
  {
    path: '/vipManagement',
    name: 'vipManagement',
    component: () => import('@/views/home/vipManagement'),
    meta: {
      title: '会员管理'
    }
  },
  {
    path: '/record',
    name: 'record',
    component: () => import('@/views/home/record'),
    meta: {
      title: '记录'
    }
  },
  {
    path: '/writeOff',
    name: 'writeOff',
    component: () => import('@/views/setting/writeOff'),
    meta: {
      title: '核销订单'
    }
  },
  {
    path: '/stockTransfer',
    name: 'stockTransfer',
    component: () => import('@/views/home/stockTransfer'),
    meta: {
      title: '调货管理'
    }
  },
  {
    path: '/addStockTransfer',
    name: 'addStockTransfer',
    component: () => import('@/views/home/addStockTransfer'),
    meta: {
      title: '新增调货'
    }
  },
  {
    path: '/addMember',
    name: 'addMember',
    component: () => import('@/views/home/addMember'),
    meta: {
      title: '新增会员',
      parent_name: 'vipManagement'
    }
  },
  {
    path: '/memberrecord',
    name: 'memberrecord',
    component: () => import('@/views/home/memberrecord'),
    meta: {
      title: '消费记录',
      parent_name: 'vipManagement'
    }
  },
  {
    path: '/revenue',
    name: 'revenue',
    component: () => import('@/views/dataTable/revenue'),
    meta: {
      title: '报表'
    }
  },
  {
    path: '/addQiantaiPrint',
    name: 'addQiantaiPrint',
    component: () => import('@/views/setting/addQiantaiPrint'),
    meta: {
      title: '新增前台打印机',
      parent_name: 'featureSet'
    }
  },
  {
    path: '/addWorker',
    name: 'addWorker',
    component: () => import('@/views/setting/addWorker'),
    meta: {
      title: '新增员工',
      parent_name: 'featureSet'
    }
  },
  {
    path: '/ShopUnit',
    name: 'ShopUnit',
    component: () => import('@/views/home/ShopUnit'),
    meta: {
      title: '单位管理'
    }
  },
  {
    path: '/stockCheck',
    name: 'stockCheck',
    component: () => import('@/views/setting/stock-check'),
    meta: {
      title: '库存盘点'
    }
  },
  {
    path: '/stockCheckDetail',
    name: 'stockCheckDetail',
    component: () => import('@/views/setting/stock-check-detail'),
    meta: {
      title: '盘点记录'
    }
  },
  {
    path: '/addLabelPrint',
    name: 'addLabelPrint',
    component: () => import('@/views/setting/addLabelPrint'),
    meta: {
      title: '新增标签打印机'
    }
  },
  {
    path: '/labelSetting',
    name: 'labelSetting',
    component: () => import('@/views/setting/label-setting'),
    meta: {
      title: '标签设置'
    }
  },
  {
    path: '/toprintForGood',
    name: 'toprintForGood',
    component: () => import('@/views/setting/toprintForGood'),
    meta: {
      title: '打印商品标签'
    }
  },
  {
    path: '/coupon-add',
    name: 'coupon-add',
    component: () => import('@/views/setting/coupon-add'),
    meta: {
      title: '新增优惠券'
    }
  },
  {
    path: '/member-coupon',
    name: 'member-coupon',
    component: () => import('@/views/home/member-coupon'),
    meta: {
      title: '查看优惠券'
    }
  },
  {
    path: '/smsSet',
    name: 'smsSet',
    component: () => import('@/views/setting/smsSet'),
    meta: {
      title: '短信设置'
    }
  },
  {
    path: '/coupon-manage',
    name: 'coupon-manage',
    component: () => import('@/views/setting/coupon-manage'),
    meta: {
      title: '优惠券'
    }
  },
  {
    path: '/recharge-scheme',
    name: 'recharge-scheme',
    component: () => import('@/views/setting/recharge-scheme'),
    meta: {
      title: '充值有礼'
    }
  },
  {
    path: '/recharge-scheme-add',
    name: 'recharge-scheme-add',
    component: () => import('@/views/setting/recharge-scheme-add'),
    meta: {
      title: '新增方案'
    }
  },
  {
    path: '/exchange-scheme',
    name: 'exchange-scheme',
    component: () => import('@/views/setting/exchange-scheme'),
    meta: {
      title: '积分兑换'
    }
  },
  {
    path: '/exchange-scheme-add',
    name: 'exchange-scheme-add',
    component: () => import('@/views/setting/exchange-scheme-add'),
    meta: {
      title: '新增方案'
    }
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.name == 'login' || to.name == 'forgetPassword') {
    store.dispatch("setNowTimeStr");
    store.commit('updateIsShowNavtar', false);
    next()
  } else if (to.name == 'index') {
    store.dispatch("setNowTimeStr");
    store.commit('updateIsShowNavtar', true);
    next()
  } else {
    store.commit('updateIsShowNavtar', true);
    next()
  }
});

export default router
