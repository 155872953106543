
import { Toast } from 'vant';
import store from '../store'
import { checkLabelPrint, formatTime, updatePrinterInfo } from './util';

export function searchDevice(v, type) {
    return new Promise((resolve, reject) => {
        OutSunmiPrint.searchDeviceConnect([{'deviceType':v}]).then(function(info) {
            console.log('搜索成功 : ' + JSON.stringify(info));
            var jsonData    = JSON.stringify(info);
            if (type == 3) {
                var tagList    = JSON.parse(jsonData).data;
                console.log('tagList-----', tagList);
                var tagDeviceList  = JSON.parse(tagList);
                console.log('tagDeviceList', tagDeviceList);
                store.commit('updateLabelDeviceList', tagDeviceList);
                // console.log('labelDeviceList', store.getters.labelDeviceList);
            } else {
                var infoList    = JSON.parse(jsonData).data;
                var deviceList  = JSON.parse(infoList);
                console.log('infoList-----', infoList);
                store.commit('updateOutDeviceList', deviceList);
            }
            resolve();
            /*这部分使用Jquery完成，仅供参考*/
            // if (deviceList.length > 0 && v == "bluetooth") {
            //     var blueaddr = $('#blueaddr'); //蓝牙下拉选择
            //     blueaddr.find('option').remove();
            //     for (var i = 0; i < deviceList.length; i++) {
            //         var item = deviceList[i];
            //         blueaddr.append('<option value="' + item.address + '">' + item.deviceName + '</option>');
            //     }
            // }else if(deviceList.length > 0 && v == "usb"){
            //     var usbaddr = $('#usbaddr'); //USB下拉选择
            //     usbaddr.find('option').remove();
            //     for (var i = 0; i < deviceList.length; i++) {
            //         var item = deviceList[i];
            //         usbaddr.append('<option value="' + item.deviceName + '">' + item.deviceProductName + '</option>');
            //     }
            // }else{

            // }
        }).catch(function(error){
            Toast('搜索设备失败')
            // console.log('搜索报错 : ' + JSON.stringify(error));
        });
    })
}

export async function connectAuto(nowdata) {
    // console.log('connectAuto----', nowdata, localStorage.printer_name, localStorage.printer_address);
    if (!localStorage.printer_name || localStorage.printer_name == '' || !localStorage.printer_address || localStorage.printer_address == '') {
        Toast('请前往【设置】-【打印机设置】中查看【本机】打印机是否配置正确')
        return;
    }
    if (localStorage.printType == '1') { // USB
        const tagR = await checkUsbConnect();
        if (tagR.code != 1) {
            await searchDevice('usb');
            if (store.state.outDeviceList.length > 0) {
                const index = store.state.outDeviceList.findIndex(e => (e.deviceProductName == localStorage.printer_name && e.deviceName == localStorage.printer_address));
                // console.log('connectAuto,USB-');
                if (index > -1) { // 如果设备名称和地址都匹配
                    connectUSB(localStorage.printer_name, localStorage.printer_address);
                } else { // 如果名称和地址都不匹配
                    const idx = store.state.outDeviceList.findIndex(e => (e.deviceProductName == localStorage.printer_name)); // 查找设备名称
                    if (idx > -1) { // 查找到设备名称，替换对应系统中地址，连接USB
                        const p_address = store.state.outDeviceList[idx].deviceName;
                        localStorage.setItem('printer_address', p_address);
                        updatePrinterInfo(nowdata, 1, p_address);
                        connectUSB(localStorage.printer_name, localStorage.printer_address);
                    } else {
                        Toast('前台USB打印机未连接')
                    }
                }
            }
        }
    } else if (localStorage.printType == '3') { // 蓝牙
        if (!sessionStorage.connectStatus || sessionStorage.connectStatus == 0) {
            await searchDevice('bluetooth');
            if (store.state.outDeviceList.length > 0) {
                const index = store.state.outDeviceList.findIndex(e => (e.deviceName == localStorage.printer_name && e.address == localStorage.printer_address));
                // console.log('connectAuto,蓝牙-');
                if (index > -1) {
                    connectBluetooth(localStorage.printer_name, localStorage.printer_address);
                } else {
                    const idx = store.state.outDeviceList.findIndex(e => (e.deviceName == localStorage.printer_name)); // 查找设备名称
                    if (idx > -1) { // 查找到设备名称，替换对应系统中地址，连接USB
                        const p_address = store.state.outDeviceList[idx].address;
                        localStorage.setItem('printer_address', p_address);
                        updatePrinterInfo(nowdata, 1, p_address);
                        connectBluetooth(localStorage.printer_name, localStorage.printer_address);
                    } else {
                        Toast('前台蓝牙打印机未连接')
                    }
                }
            }
        }
    }
}

export async function connectAutoLabel(v, nowdata) {
    console.log('connectAutoLabel', v, nowdata.printer_name, nowdata.printer_address);
    await searchDevice(v, 3);
    if (v == 'usb') {
        console.log('store.state.labelDeviceList', store.state.labelDeviceList);
        const index = store.state.labelDeviceList.findIndex(e => (e.deviceProductName == nowdata.printer_name && e.deviceName == nowdata.printer_address));
        console.log('connectAutoLabel,usb-', index);
        if (index > -1) {
            connectLabel(nowdata.printer_name, nowdata.printer_address);
        } else {
            const idx = store.state.labelDeviceList.findIndex(e => (e.deviceProductName == nowdata.printer_name)); // 查找设备名称
            if (idx > -1) { // 查找到设备名称，替换对应系统中地址，连接USB
                const p_address = store.state.labelDeviceList[idx].deviceName;
                updatePrinterInfo(nowdata, 3, p_address);
                setTimeout(() => {
                    connectLabel(nowdata.printer_name, p_address);
                }, 100);
            } else {
                Toast('标签打印机未连接')
            }
        }
    } else if (v == 'bluetooth') {
        const index = store.state.labelDeviceList.findIndex(e => (e.deviceName == nowdata.printer_name && e.address == nowdata.printer_address));
        console.log('connectAutoLabel,蓝牙-', index);
        if (index > -1) {
            connectBluTagPrint(nowdata.printer_name, nowdata.printer_address);
        } else {
            const idx = store.state.labelDeviceList.findIndex(e => (e.deviceName == nowdata.printer_name)); // 查找设备名称
            if (idx > -1) { // 查找到设备名称，替换对应系统中地址，连接USB
                const p_address = store.state.labelDeviceList[idx].address;
                updatePrinterInfo(nowdata, 3, p_address);
                setTimeout(() => {
                    connectBluTagPrint(nowdata.printer_name, p_address);
                }, 100);
            } else {
                Toast('标签打印机未连接')
            }
        }
    }
}

/**
 * USB连接
 * @param deviceName 选中的设备名称
 * @param deviceAddress 选中的设备地址
 */
export function connectUSB(deviceName, deviceAddress) {
    // console.log( "选中的值：" + deviceName + "( "+ deviceAddress +" )" );
    OutSunmiPrint.usbDeviceConnect([{'deviceName':deviceName,'deviceAddress':deviceAddress}]).then(function(info) {
        // localStorage.setItem('printer_name', deviceName)
        // localStorage.setItem('printer_address', deviceAddress)
        // console.log('USB连接Success : ' + JSON.stringify(info));
        // Toast('USB连接成功')
    }).catch(function(error){
        Toast('USB连接失败')
        // console.log('USB连接Failed : ' + JSON.stringify(error));
    });
}

/**
 * 蓝牙连接
 * @param deviceName 选中的设备名称
 * @param deviceAddress 选中的设备地址
 */
export function connectBluetooth(deviceName, deviceAddress) {
    // console.log( "选中的值：" + deviceName + "( "+ deviceAddress +" )" );
    sessionStorage.setItem('connectStatus', 1);
    // return
    OutSunmiPrint.bluetoothDeviceConnect([{'deviceName':deviceName,'deviceAddress':deviceAddress}]).then(function(info) {
        Toast('蓝牙连接成功')
        // localStorage.setItem('printer_name', deviceName)
        // localStorage.setItem('printer_address', deviceAddress)
        // console.log('蓝牙连接Success : ' + JSON.stringify(info));
    }).catch(function(error){
        Toast('蓝牙连接蓝牙连接失败')
        // console.log('Failed : ' + JSON.stringify(error));
    });
}

// 断开连接
export function closeConnect() {
    sessionStorage.setItem('connectStatus', 0);
    OutSunmiPrint.deviceConnectClose().then(function(info) {
        // console.log('断开连接Success : ' + JSON.stringify(info));
    }).catch(function(error){
        // console.log('断开连接Failed : ' + JSON.stringify(error));
    });
}

/**
 * 开始打印
 * @param deviceName 选中的设备名称
 * @param deviceAddress 选中的设备地址
 */
 export function beginPrint(data, size) {
    // console.log('开始打印---', JSON.stringify(data), size);
    return new Promise((resolve, reject) => {
        // resolve(1)
        if(size == "58"){
            // console.log('58打印');
            OutSunmiPrint.beginPrint58(data).then(function(info) {
                // console.log('58打印成功Success : ' + JSON.stringify(info));
                resolve(1);
            }).catch(function(error){
                // console.log('58打印失败Failed : ' + JSON.stringify(error));
                resolve(2);
            });
        }else if(size == "80"){
            // console.log('80打印');
            OutSunmiPrint.beginPrint80(data).then(function(info) {
                // console.log('80打印成功Success : ' + JSON.stringify(info));
                resolve(1);
            }).catch(function(error){
                // console.log('80打印失败Failed : ' + JSON.stringify(error));
                resolve(2);
            });
        }
    })
}

/**
 * 二维码打印
 * @param {选中的设备名称} deviceName
 * @param {选中的设备地址} deviceAddress
 */
 export function imagePrint(data, size) {
    return new Promise((resolve, reject) => {
        OutSunmiPrint.printImage([{"content":data,"sizeType":size}]).then(function(info) {
            Toast('打印成功')
            //console.log('Success : ' + info);
            // console.log("图片地址：" + JSON.stringify(info));
        }).catch(function(error){
            Toast('打印失败')
            // console.log('Failed : ' + JSON.stringify(error));
        });
    })
}

// 电子秤初始化
export function initScale(action) {
    return new Promise((resolve, reject) => {
        if (typeof OutSunmiPrint != 'undefined') {
            OutSunmiPrint.initElectronic([{'action': action}]).then(function(info) {
                console.log('电子秤初始化Success : ' + JSON.stringify(info));
                resolve();
            }).catch(function(error){
                console.log('电子秤初始化Failed : ' + JSON.stringify(info));
                Toast('电子秤初始化失败')
                reject();
            });
        } else {
            console.log('OutSunmiPrint Failed');
            Toast('电子秤初始化失败')
            reject();
        }
    })
}

// 获取电子秤净重、状态、数值返回
export function getScaleData() {
    //监听事件变化
    console.log('getScaleData');
    // store.commit('updateWeightDetail', { weight: 0.170, status: 53 });
    window.addEventListener("newEData",({detail}) => {
        console.log('getScaleData 电子秤数据', JSON.stringify(detail));
        store.commit('updateWeightDetail', detail);
    })
}

// 电子秤去皮
export function peelScale() {
    return new Promise((resolve, reject) => {
        if (typeof OutSunmiPrint != 'undefined') {
            OutSunmiPrint.initElectronic([{'action':'removepeel'}]).then(function(info) {
                // console.log('电子秤去皮Success : ' + JSON.stringify(info));
                resolve();
            }).catch(function(error){
                // console.log('电子秤去皮Failed : ');
                reject();
            });
        } else {
            Toast('无法找到电子秤')
        }
    })
}

// 电子秤置零
export function zeroSettScale() {
    return new Promise((resolve, reject) => {
        if (typeof OutSunmiPrint != 'undefined') {
            OutSunmiPrint.initElectronic([{'action':'turnzero'}]).then(function(info) {
                // console.log('电子秤置零Success : ');
                resolve();
            }).catch(function(error){
                // console.log('电子秤置零Failed : ');
                reject();
            });
        } else {
            Toast('无法找到电子秤')
        }
    })
}

// 获取设备信息
export function deviceInfo() {
    if (typeof OutSunmiPrint != 'undefined') {
        OutSunmiPrint.deviceInfo().then(function(info) {
            store.commit('updateDeviceInfo', info);
            // console.log('获取设备信息Success : ' + JSON.stringify(info));
            store.commit('updateDeviceMac', info.uuid);
            //console.log('Success : ' +info);
        }).catch(function(error){
            // console.log('获取设备信息Failed : ' +error);
        });
    } else {
        
    }
}

// 获取设备信息
export function deviceInfoForUUID() {
    if (typeof OutSunmiPrint != 'undefined') {
        OutSunmiPrint.deviceInfo().then(function(info) {
            console.log('获取设备信息Success : ' + JSON.stringify(info));
            localStorage.setItem("device_code", info.uuid);
        }).catch(function(error){
            // console.log('获取设备信息Failed : ' +error);
        });
    } else {
        
    }
}

// 打开钱箱
export function openBox() {
    if (typeof OutSunmiPrint != 'undefined') {
        // console.log('localStorage.printer_name', localStorage.printer_name);
        let device_name = '';
        if (localStorage.box_type == '1') {
            device_name = 'HCDHD';
        } else if (localStorage.box_type == '3') {
            device_name = 'MT';
        } else {
            device_name = localStorage.printer_name || ''
        }
        // console.log('device_name', device_name);
        OutSunmiPrint.openBox([{"deviceName": device_name}]).then(function(info) {
            // console.log('打开钱箱Success : ' + JSON.stringify(info));
        }).catch(function(error){
            // console.log('打开钱箱Failed : ' + JSON.stringify(error));
            Toast('打开钱箱失败：' + JSON.stringify(error));
        });
    } else {
        Toast('无法找到设备')
    }
}

// IP连接
export function ipConnect(deviceName, deviceAddress, content, size) {
    return new Promise((resolve, reject) => {
        // console.log('IP连接--', deviceName, deviceAddress);
        // console.log('IP连接--content', JSON.stringify(content));
        DzbIPPrint.ipConnect2([{'deviceName': deviceName,'deviceAddress': deviceAddress, 'content': content, 'size': size}]).then(function(info) {
            // console.log('IP连接成功 Success : ' + JSON.stringify(info));
            resolve(1);
        }).catch(function(error){
            // console.log('Failed : ' + JSON.stringify(error));
            resolve(2);
        });
        resolve();
    })
}

export function getPrintResInfo() {
    // console.log('监听网络打印机打印状态');
    window.addEventListener("printInfo",({detail}) => {
        // console.log("网络打印信息监听：" + JSON.stringify(detail));
        if (detail.code) {
            store.commit('updateHcPrintRes', detail);
        } else {
            // console.log("网络打印信息监听：" + JSON.stringify(detail));
            store.commit('updateHcPrintRes', { code: 3, msg: '打印失败，请重新连接打印机' });
        }
    })
}

// 检测USB打印机连接状态
export function checkUsbConnect(params) {
    return new Promise((resolve, reject) => {
        OutSunmiPrint.checkConnectStatus().then(function(info) {
            // console.log('checkConnectStatus Success : ' + JSON.stringify(info));
            store.commit('updateUsbConnectStatus', info);
            if (info.code != 1) {
                Toast(info.msg || '前台USB打印机连接异常');
                if (params != 1) {
                    const voice_ = store.state.otherVoice;
                    voice_.push(`${info.msg || '前台USB打印机连接异常'}`);
                    store.commit('updateOtherVoice', voice_);
                }
            } else {
                store.commit('updateOtherVoice', []);
            }
            resolve(info);
        }).catch(function(error){
            store.commit('updateUsbConnectStatus', {code: 2, msg: '前台USB打印机连接异常'});
            resolve({code: 2, msg: '前台USB打印机连接异常'});
            // console.log(error ? 'checkConnectStatus Failed : ' + JSON.stringify(error) : 'checkConnectStatus Failed');
        });
    })
}

// 检测标签打印机连接状态
export function checkTagConnect(params) {
    return new Promise((resolve, reject) => {
        OutSunmiPrint.checkConnectTagStatus().then(function(info) {
            console.log('checkConnectTagStatus Success : ' + JSON.stringify(info));
            store.commit('updateLabelConnectStatus', info);
            if (info.code != 1) {
                Toast(info.msg || '标签打印机连接异常');
            }
            resolve(info);
        }).catch(function(error){
            console.log('checkConnectTagStatus Failed : ' + JSON.stringify(error));
        });
    })
}

// 检测标签打印机打印状态
export function checkLabelPrintStatus(params) {
    return new Promise((resolve, reject) => {
        OutSunmiPrint.checkTagPrintStatus().then(function(info) {
            store.commit('updateLabelPrintStatus', info);
            // console.log('checkTagPrintStatus Success : ' + JSON.stringify(info));
            if (info.code != 1) {
                Toast(info.msg || '标签打印机打印异常，请检查打印机连接');
                const voice_ = store.state.otherVoice;
                voice_.push(`${info.msg || '标签打印机打印异常，请检查打印机连接'}`);
                store.commit('updateOtherVoice', voice_);
            } else {
                store.commit('updateOtherVoice', []);
            }
            resolve(info);
        }).catch(function(error){
            // console.log('checkTagPrintStatus Failed : ' + JSON.stringify(info));
        });
    })
}

// 连接标签打印机
 export function connectLabel(deviceName, deviceAddress) {
    // console.log( "标签打印机选中的值：" + deviceName + "( "+ deviceAddress +" )" );
    OutSunmiPrint.connectTagPrint([{'deviceAddress':deviceAddress}]).then(function(info) {
        // console.log('Label Success : ' + JSON.stringify(info));
        Toast(info.msg || '');
    }).catch(function(error){
        Toast(`标签打印机${deviceName}连接失败`);
        // console.log('Label Failed : ' + JSON.stringify(error));
    });
}

/**
 * 开始打印
 * @param data 模板数据
 */
 export function labelPrint(tagContent, size) {
    // console.log('标签开始打印---', JSON.stringify(tagContent), size);
    return new Promise((resolve, reject) => {
        if (size == 60) {
            OutSunmiPrint.tagPrintText(tagContent).then(function(info) {
                // console.log('Lable Print Success : ' + JSON.stringify(info));
                resolve(1)
            }).catch(function(error){
                // console.log('Label Print Failed : ' + JSON.stringify(error));
                resolve(2);
            });
        } else {
            //适用于二寸
            OutSunmiPrint.tagPrintText2(tagContent).then(function(info) {
                // console.log('Label Print 50 Success : ' + JSON.stringify(info));
                resolve(1)
            }).catch(function(error){
                // console.log('Label Print 50 Failed : ' + JSON.stringify(error));
                resolve(2);
            });
        }
    })
}

// USB插入拔出或开机关机，接收到广播信息
export function getListener() {
    if (window.broadcaster) {
        // console.log("111111111111" + window.broadcaster);
        var listener = function( e ) {
            //接收到广播信息
            // console.log( "didShow received! userInfo: ", JSON.parse(e.data));
            const e_data = JSON.parse(e.data);
            const e_d_data = JSON.parse(e_data.data);
            if (e_data.isAdd == 1) { // 有打印机添加
                // 前台USB
                const index_q = e_d_data.findIndex(e => (e.deviceProductName == localStorage.printer_name && e.deviceName == localStorage.printer_address));
                if (index_q > -1) { // 如果设备名称和地址都匹配
                    // console.log('-------------1');
                    connectUSB(localStorage.printer_name, localStorage.printer_address);
                } else { // 如果名称和地址都不匹配
                    const idx_q = e_d_data.findIndex(e => (e.deviceProductName == localStorage.printer_name)); // 查找设备名称
                    if (idx_q > -1) { // 查找到设备名称，替换对应系统中地址，连接USB
                        // console.log('@@@@@@@@@@@@@@@@@@@@@@@2');
                        const p_address = e_d_data[idx_q].deviceName;
                        localStorage.setItem('printer_address', p_address);
                        updatePrinterInfo(store.state.qtPrinter, 1, p_address);
                        connectUSB(localStorage.printer_name, localStorage.printer_address);
                    } else {
                        const tag_ = store.state.tagPrinter[0];
                        const index_l = e_d_data.findIndex(e => (e.deviceProductName == tag_.printer_name && e.deviceName == tag_.printer_address));
                        if (index_l > -1) {
                            // console.log('^^^^^^^^^^^^^^^^^^^^^^6');
                            connectLabel(tag_.printer_name, tag_.printer_address);
                        } else {
                            const idx_l = e_d_data.findIndex(e => (e.deviceProductName == tag_.printer_name)); // 查找设备名称
                            if (idx_l > -1) { // 查找到设备名称，替换对应系统中地址，连接USB
                                // console.log('****************************8');
                                const p_address = e_d_data[idx_l].deviceName;
                                updatePrinterInfo(tag_, 3, p_address);
                                connectLabel(tag_.printer_name, p_address);
                            }
                        }
                    }
                }
            }
        }
        window.broadcaster.addEventListener( "didShow", listener);
    } else {
        // console.log('windown.broadcaster not available');
    }
}

// 美团打印机相关
// 是否支持内置打印
export function isPrinterSupported(name) {
    // console.log('~~~~~~~~name', name, '进入isPrinterSupported');
    // console.log('~~~~~~~~name', name, typeof OutSunmiPrint);
    return new Promise((resolve, reject) => {
        if (typeof OutSunmiPrint != 'undefined') {
            // console.log('！！！开始OutSunmiPrint.mtIsPrinterSupported');
            OutSunmiPrint.mtIsPrinterSupported().then(function(info) {
                // console.log('****************1', name, '是否支持内置打印Success : ', info);
                resolve(info);
            }).catch(function(error){
                // console.log('****************2', name, '是否支持内置打印Error : ' + JSON.stringify(error));
                resolve({ code: 2, msg: '内置打印机异常' });
            });
        } else {
            // console.log('****************3', name, '是否支持内置打印 OutSunmiPrint', typeof OutSunmiPrint);
            resolve({ code: 2, msg: '内置打印机异常' });
        }
    })
}
// 获取打印机状态
export function getPrinterStatus() {
    if (typeof OutSunmiPrint != 'undefined') {
        return new Promise((resolve, reject) => {
            OutSunmiPrint.mtGetPrinterStatus().then(function(info) {
                // console.log('获取打印机状态Success : ', info);
                store.commit('updateMtPrinterStatus', info);
                if (info.code != 1) {
                    Toast(info.msg || '内置打印机状态异常');
                    if (params != 1) {
                        const voice_ = store.state.otherVoice;
                        voice_.push(`${info.msg || '内置打印机状态异常'}`);
                        store.commit('updateOtherVoice', voice_);
                    }
                } else {
                    store.commit('updateOtherVoice', []);
                }
                resolve(info);
            }).catch(function(error){
                // console.log('获取打印机状态Error : ' + JSON.stringify(error));
            });
        })
    }
}
// 获取打印机类型（50或80）
export function getPrinterPaperType() {
    if (typeof OutSunmiPrint != 'undefined') {
        return new Promise((resolve, reject) => {
            OutSunmiPrint.mtGetPrinterPaperType().then(function(info) {
                // console.log('获取打印机类型Success : ', info);
                resolve(info);
            }).catch(function(error){
                // console.log('获取打印机类型Error : ' + JSON.stringify(error));
                resolve({ code: 2, msg: JSON.stringify(error) });
            });
        })
    }
}
// 内置打印机打印
export function mtPrintText(data, size) {
    if (typeof OutSunmiPrint != 'undefined') {
        return new Promise((resolve, reject) => {
            if (size == 58) {
                OutSunmiPrint.mtPrintText58(data).then(function(info) {
                    // console.log('内置打印机打印-58-Success : ', info);
                    resolve(info);
                }).catch(function(error){
                    // console.log('内置打印机打印-58-Error : ' + JSON.stringify(error));
                    resolve({ code: 2, msg: JSON.stringify(error) });
                });
            } else if (size == 80) {
                // 80mm
                OutSunmiPrint.mtPrintText(data).then(function(info) {
                    // console.log('内置打印机打印-80-Success : ', info);
                    resolve(info);
                }).catch(function(error){
                    // console.log('内置打印机打印-80-Error : ' + JSON.stringify(error));
                    resolve({ code: 2, msg: JSON.stringify(error) });
                });
            }
        })
    } else {
        Toast('内置打印机异常！');
    }
}
// 是否支持内置电子秤
export function isScaleSupported() {
    return new Promise((resolve, reject) => {
        if (typeof OutSunmiPrint != 'undefined') {
            OutSunmiPrint.mtIsScaleSupported().then(function(info) {
                // console.log('是否支持内置电子秤Success : ', info);
                resolve(info);
            }).catch(function(error){
                // console.log('是否支持内置电子秤Error : ' + JSON.stringify(error));
                resolve({ code: 2, msg: '内置电子秤异常' });
            });
        } else {
            // console.log('内置电子秤异常', typeof OutSunmiPrint);
            resolve({ code: 2, msg: '内置电子秤异常' });
        }
    })
}
// 电子秤初始化，price为单价
export function getScaleInfo(price) {
    // console.log('getScaleInfo', price);
    if (typeof OutSunmiPrint != 'undefined') {
        OutSunmiPrint.mtGetScaleInfo([{'price': price}])
        // 会报错，所以注释了
        // .then(function(info) {
        //     console.log('电子秤初始化Success : ', info);
        // }).catch(function(error){
        //     console.log('电子秤初始化Error : ' + JSON.stringify(error));
        // });
    }
}
// 监听电子秤质量变化
export function getScaleDataMt() {
    // console.log('getScaleDataMt');
    //监听事件变化
    window.addEventListener("scaleData",({detail}) => {
        console.log('getScaleDataMt 监听电子秤质量变化', JSON.stringify(detail));
        store.commit('updateWeightDetail', detail);
    })
}
// 清除事件监听
export function removeScaleListener() {
    // console.log('removeScaleListener');
    //监听事件变化
    window.removeEventListener("scaleData",({detail}) => {
        // console.log('removeScaleListener', detail);
    });
}
// 重置电子秤
export function forceZeroing() {
    if (typeof OutSunmiPrint != 'undefined') {
        OutSunmiPrint.mtForceZeroing().then(function(info) {
            // console.log('重置电子秤Success : ', info);
        }).catch(function(error){
            // console.log('重置电子秤Error : ' + JSON.stringify(error));
        });
    }
}
// 置零电子秤
export function doZeroing() {
    if (typeof OutSunmiPrint != 'undefined') {
        OutSunmiPrint.mtDoZeroing().then(function(info) {
            // console.log('置零电子秤Success : ', info);
        }).catch(function(error){
            // console.log('置零电子秤Error : ' + JSON.stringify(error));
        });
    }
}
// 去皮电子秤
export function doTaring() {
    if (typeof OutSunmiPrint != 'undefined') {
        OutSunmiPrint.mtDoTaring().then(function(info) {
            // console.log('去皮电子秤Success : ', info);
        }).catch(function(error){
            // console.log('去皮电子秤Error : ' + JSON.stringify(error));
        });
    }
}

// 苹果打印相关方法
// 搜索蓝牙
export function searchBleConnect() {
    return new Promise((resolve, reject) => {
        DzbIosPrint.searchBleConnect().then(function(info) {
            // console.log("数据获取数组", info, typeof info);
            const info_ = typeof info == 'string' ? JSON.parse(info) : info;
            if (info_.code) {
                const arr = info_.data && info_.data != '' ? info_.data.split(',') : [];
                // console.log('arr===', arr);
                store.commit('updateBluetoothDeviceIos', arr);
            } else {
                Toast(info_.msg || '扫描设备失败');
                store.commit('updateBluetoothDeviceIos', []);
            }
            resolve();
        }).catch(function(error){
            // console.log('searchBleConnect Failed : ' + error);
            Toast('扫描设备失败');
        });
    })
}
// 连接蓝牙
export function bleConnect(deviceNo) {
    console.log('deviceNo', deviceNo);
    DzbIosPrint.bleConnect([{'deviceNo':deviceNo}]).then(function(info) {
        console.log('bleConnect Success : ', JSON.stringify(info), typeof info);
    }).catch(function(error){
        console.log('bleConnect fail : ' + JSON.stringify(error));
    });
}
// 断开蓝牙
export function deviceConnectClose() {
    DzbIosPrint.deviceConnectClose().then(function(info) {
        // console.log('deviceConnectClose Success : ' + JSON.stringify(info));
    }).catch(function(error){
        // console.log('deviceConnectClose fail : ' + JSON.stringify(error));
    });
}
// 获取蓝牙连接状态
export function getBleConnectStatus(param) {
    return new Promise((resolve, reject) => {
        DzbIosPrint.getBleConnectStatus().then(function(info) {
            // console.log('getBleConnectStatus Success : ', info, typeof info);
            const info_ = typeof info == 'string' ? JSON.parse(info) : info;
            // console.log('info_', info_);
            store.commit('updateBlelabelConnectStatus', info_);
            if (info_.code != 1) {
                // console.log('info_.code != 1');
                Toast('标签打印机连接异常');
                if (param != 1) {
                    // console.log('param != 1');
                    const voice_ = store.state.otherVoice;
                    voice_.push(`${'标签打印机连接异常'}`);
                    store.commit('updateOtherVoice', voice_);
                }
            } else {
                store.commit('updateOtherVoice', []);
            }
            resolve(info_);
        }).catch(function(error){
            // console.log('getBleConnectStatus fail : ' + JSON.stringify(error));
        });
    })
}
// 打印
export function tagPrintText(tagContent) {
    DzbIosPrint.tagPrintText(tagContent).then(function(info) {
        // console.log('tagPrintText Success : ' + JSON.stringify(info), typeof info);
    }).catch(function(error){
        // console.log('tagPrintText fail : ' + JSON.stringify(error));
    });
}
// 自动连接蓝牙标签机
export async function connectAutoLabelBlue(nowdata) {
    await searchBleConnect();
    setTimeout(() => {
        const index = store.state.bluetoothDeviceIos.findIndex(e => (e == nowdata.printer_name));
        if (index > -1) {
            bleConnect(index);
        } else {
            Toast('标签打印机未连接')
        }
    }, 600);
}
// 扫描蓝牙
export function scanBleConnect() {
    // console.log('scanBleConnect 开始扫描蓝牙', formatTime(new Date(), 'yyyy-MM-dd hh:mm:ss'));
    DzbIosPrint.scanBleConnect();
}

// 和成东
// 初始化电子秤：hcdconnect；去皮：hcdremovepeel；置零：hcdturnzero；关闭：hcdclose；
export function initHcdElectronic(action) {
    // console.log("initHcdElectronic", action);
    OutSunmiPrint.initHcdElectronic([{'action': action}]).then(function(info) {
        // console.log('initHcdElectronic Success : ' + JSON.stringify(info));
    }).catch(function(error){
        // console.log('initHcdElectronic Failed : ' + JSON.stringify(error));
    });
}

// 监听数值变化
export function getScaleDateHcd() {
    window.addEventListener("newEData2",({detail}) => {
        console.log('getScaleDateHcd newEData2::', JSON.stringify(detail));
        let data = {
            weight: detail.weight && !isNaN(detail.weight) ? (detail.weight / 1000).toFixed(3) : 0,
            status: detail.status,
        }
        store.commit('updateWeightDetail', data);
    }); 
}

// HCD内置扫码 开启：starthcdscan；关闭：stophcdscan；
export function initHCDScannerDecode(action) {
    // console.log('initHCDScannerDecode', action);
    if (typeof OutSunmiPrint != 'undefined') {
        OutSunmiPrint.initHCDScannerDecode([{'action': action}]).then(function(info) {
            // console.log('initHCDScannerDecode Success : ' + JSON.stringify(info));
            store.commit('updateHcdBarcode', info);
        }).catch(function(error){
            // console.log('initHCDScannerDecode Failed : ' + JSON.stringify(error));
            store.commit('updateHcdBarcode', { code: 2, data: '', });
        });
    } else {
        store.commit('updateHcdBarcode', { code: 2, data: '', });
        Toast('内置扫码开启失败');
    }
}

// 商米
// 初始化
export function connectSunMiScaleService() {
    return new Promise((resolve, reject) => {
        OutSunmiPrint.connectSunMiScaleService().then(function(info) {
            // console.log('connectSunMiScaleService Success : ' + JSON.stringify(info));
            // if (info.code != 1) {
            //     Toast(info.msg);
            // }
            resolve(info);
        }).catch(function(error){
            // console.log(error ? 'connectSunMiScaleService Error : ' + JSON.stringify(error) : 'connectSunMiScaleService Error');
            resolve({ code: 2, status: 2, msg: '电子秤异常' });
        });
    })
}
// 关闭
export function sunMiClose() {
    OutSunmiPrint.sunMiClose().then(function(info) {
        // console.log('sunMiClose Success : ' + JSON.stringify(info));
    }).catch(function(error){
        // console.log(error ? 'sunMiClose Error : ' + JSON.stringify(error) : 'sunMiClose Error');
    });
}
// 归零
export function sunMiTurnZero() {
    OutSunmiPrint.sunMiTurnZero().then(function(info) {
        // console.log('sunMiTurnZero Success : ' + JSON.stringify(info));
    }).catch(function(error){
        // console.log(error ? 'sunMiTurnZero Error : ' + JSON.stringify(error) : 'sunMiTurnZero');
    });
}
// 去皮
export function sunMiTurnTare() {
    OutSunmiPrint.sunMiTurnTare().then(function(info) {
        // console.log('sunMiTurnTare Success : ' + JSON.stringify(info));
    }).catch(function(error){
        // console.log(error ? 'sunMiTurnTare Error : ' + JSON.stringify(error) : 'sunMiTurnTare Error');
    });
}
// 获取打印信息
export function sunMiScaleDataInfo() {
    OutSunmiPrint.sunMiScaleDataInfo()
    // 会报错先注释
    // .then(function(info) {
    //     console.log('sunMiScaleDataInfo Success : ' + JSON.stringify(info));
    // }).catch(function(error){
    //     console.log(error ? 'sunMiScaleDataInfo Error : ' + JSON.stringify(error) : 'sunMiScaleDataInfo Error');
    // });
}
// 监听数值变化
export function getScaleDateSm(params) {
    window.addEventListener("sunMiEData",({detail}) => {
        console.log("getScaleDateSm 最新数值：" + JSON.stringify(detail));
        let data = {
            weight: detail.net && !isNaN(detail.net) ? (detail.net / 1000).toFixed(3) : 0,
            status: detail.status,
            tare: detail.tare,
        }
        store.commit('updateWeightDetail', data);
    })
}

// 标签机打印条码
//打印内容参考
var tagBarContent =[{
    "printType":"0", //打印类型， 0 为普通 1为预包装参考下面内容格式
    "sizeType":"2", //打印尺寸 1=50X40,2=40X30
    "foodName":"水果奶茶",
    "orderPrice":"售价: ￥20.00/个",
    "proTime":"包装日期: 2023年12月30日",
    "periodTime":"保质期: 100天",
    "brValue":"6921734976505" //条码内容
}];
//打印内容参考-预包装
var tagBarContent =[{
    "sizeType":"1",
    "printType":"1",
    "foodName":"水果奶茶",
    "mainFieldList": ["单价","重量"],
    "mainTitleList": ["5.99元/斤","10.765斤"],
    "orderPrice":"金额: 100.89元",
    "proTime":"包装日期: 2023-12-30",
    //"proTime":"",
    "periodTime":"保质期;180天",
    //"periodTime":"",
    "brValue":"6921734976505"
}];
export function tagPrintBrCode(tagBarContent) {
    OutSunmiPrint.tagPrintBrCode(tagBarContent).then(function(info) {
        console.log('tagPrintBrCode Success : ' + JSON.stringify(info));
    }).catch(function(error){
        console.log(error ? 'tagPrintBrCode Failed : ' + JSON.stringify(error) : 'tagPrintBrCode Failed');
    });
}

// 安卓连接蓝牙标签机
export function connectBluTagPrint(deviceName, deviceAddress) {
    console.log( "安卓蓝牙标签打印机选中的值：" + deviceName + "( "+ deviceAddress +" )" );
    OutSunmiPrint.connectBluTagPrint([{'deviceAddress':deviceAddress}]).then(function(info) {
        console.log('connectBluTagPrint Success : ' + JSON.stringify(info));
        Toast(info.msg || '');
    }).catch(function(error){
        console.log(error ? 'connectBluTagPrint Failed : ' + JSON.stringify(error) : 'connectBluTagPrint Failed');
        Toast(`标签打印机${deviceName}连接失败`);
    });
}

// 容大电子秤对接
// 初始化：rdconnect；去皮：rdremovepeel；置零：rdturnzero；关闭：rdclose。
export function initRdElectronic(action) {
    OutSunmiPrint.initRdElectronic([{'action': action}])
    // 会报错，注释
    // .then(function(info) {
    //     console.log('Success : ' + JSON.stringify(info));
    // }).catch(function(error){
    //     console.log('Failed : ' + JSON.stringify(info));
    // });
}
// 监听数值变化
export function getScaleDataRd() {
    window.addEventListener("newRdData",({detail}) => {
        // console.log('newRdData:', JSON.stringify(detail));
        store.commit('updateWeightDetail', detail);
    });
}