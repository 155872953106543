import api from '@/request/api'
import store from '@/store/index'
import { Toast } from 'vant'
import {
  beginPrint,
  checkUsbConnect,
  getBleConnectStatus,
  getPrinterStatus,
  mtPrintText,
  tagPrintBrCode,
  tagPrintText,
} from './outPrint.js'
import { netPrintSend, winPrintSend, getPrinterType, winPrintTagSend } from './util.js'

const printFunc = {
  async printReceipt(printdata, order_id) {
    const print_type = localStorage.printType;
    // console.log('print_type', print_type);
    const now_devie = localStorage.nowDevice;
    if (!print_type) {
      return;
    }
    const print_size = localStorage.qiantaiPrintSize;
    const print_ip = localStorage.qiantaiPrintIp;
    if (print_type == 6) { // 大趋云打印
      let p_data = this.outPrintdataProcess(printdata);
      console.log(p_data);
      // return
      let daD = {
        print_content: JSON.stringify(p_data),
        shop_id: localStorage.shop_id,
        order_id: order_id,
        cloud_sn: localStorage.qtDeviceSN,
      }
      api.daqPrint_dqPrint(daD).then(res => {
        console.log('前台 大趋云打印机 res', res);
        if (res.code == 1) {
          console.log('大趋 前台打印成功');
        } else {
          Toast(res.msg || '打印失败');
        }
      }).catch((err) => {
        console.log('前台 大趋云打印机打印失败', err);
        Toast(res.msg || '打印失败');
      });
    } else if (print_type == 11) { // 佳博云打印机
      let p_data = this.outPrintdataProcess(printdata);
      console.log(p_data);
      // return
      let daD = {
        print_content: JSON.stringify(p_data),
        shop_id: localStorage.shop_id,
        order_id: order_id,
        cloud_sn: localStorage.qtDeviceSN,
      }
      api.jiabo_print(daD).then(res => {
        console.log('前台 佳博云打印机 res', res);
        if (res.code == 1) {
          console.log('佳博 前台打印成功');
        } else {
          Toast(res.msg || '打印失败');
        }
      }).catch((err) => {
        console.log('前台 佳博云打印机打印失败', err);
        Toast(res.msg || '打印失败');
      });
    } else {
      if (now_devie == 'android' || (now_devie == 'mt_android' && (print_type == 1 || print_type == 3))) { // 安卓
        let p_data = this.outPrintdataProcess(printdata);
        console.log(p_data);
        if (print_type == 1) {
          checkUsbConnect();
        }
        beginPrint(p_data, print_size);
      } else if (now_devie == 'windows') { // Windows
        if (print_type == 4 || print_type == 1) {
          // console.log('前台--------开始调起网络打印机打印');
          if (print_type == 4 && (!print_ip || !print_size)) {
            Toast('前台打印机配置有误，请前往【设置】-【打印设置】中查看并重新配置')
            return
          }
          if (print_type == 1 && !print_size) {
            Toast('前台打印机配置有误，请前往【设置】-【打印设置】中查看并重新配置')
            return
          }
          // console.log(this.currentPrint[0].type);
          // this.printing = false;
          if (print_type == 4) {
            printdata.printChannel = 'network';
            printdata.ip = print_ip;
            printdata.size = print_size;
          } else if (print_type == 1) {
            printdata.printChannel = 'usb';
            printdata.size = print_size;
          }
          netPrintSend(printdata).then(res => {
            console.log('前台 网络打印机打印 res', res);
            if (res.code == 1) {
              console.log('前台 网络打印机打印成功1-1');
            } else {
              // console.log('前台 网络打印机打印失败---', res);
              Toast('打印失败');
            }
          }).catch((err) => {
            // console.log('前台 网络打印机打印失败', err);
            Toast(err.msg || '打印失败');
          });
        } else if (print_type == 5) { // 驱动打印
          console.log('0000000驱动打印0000000');
          winPrintSend(printdata).then(res => {
            console.log('前台 驱动打印机打印成功1-1');
          }).catch((err) => {
            Toast(err.msg || '打印失败');
          });
        }
      } else if (now_devie == 'mt_android' && print_type == 10) { // 美团收银机
        let p_data = this.outPrintdataProcess(printdata);
        console.log(p_data);
        getPrinterStatus();
        const mtRes = await mtPrintText(p_data, print_size);
        if (mtRes.code == 2) {
          Toast(mtRes.msg || '内置打印机打印失败');
        }
      }
    }
  },
  async printLabelReceipt(printdata) {
    const tag_list = store.state.tagPrinter;
    if (tag_list.length > 0) {
      const printer_type = getPrinterType(tag_list[0].printer_type, 1, 3);
      if (printer_type == 7 || printer_type == 12) {
        let p_data = this.outPrintdataProcess(printdata, tag_list[0].size.toString());
        console.log(p_data);
        tagPrintBrCode(p_data);
      } else if (printer_type == 8) {
        winPrintTagSend(printdata, tag_list[0].size.toString()).then(res => {
          console.log('标签 驱动打印机打印成功1-1');
        }).catch((err) => {
          Toast(err.msg || '打印失败');
        });
      } else if (printer_type == 9) { // IOS蓝牙
        const tagRes = await getBleConnectStatus();
        // console.log('tagRes, iOS, 打印', tagRes);
        if (tagRes.code == 1) {
          let p_data = this.outPrintdataProcess(printdata, tag_list[0].size.toString());
          console.log(p_data);
          // console.log('处理后数据', JSON.stringify(printdataOfLabel));
          let tagContent = [{"list": p_data}]
          // console.log('最终处理数据：', tagContent);
          tagPrintText(tagContent, tag_list[0].size.toString());
        } else {
          Toast('打印失败');
        }
      } else if (printer_type == 11) { // 佳博云标签机
        let p_data = this.outPrintdataProcess(printdata, tag_list[0].size.toString());
        let t_query = {
          print_content: JSON.stringify(p_data),
          cloud_sn: tag_list[0].cloud_sn,
        }
        api.jiabo_tagPrint(t_query).then(res => {
          if (res.code == 1) {
            console.log('佳博云标签机打印成功');
          } else {
            Toast(res.msg || '打印失败');
          }
        })
      }
    } else {
      Toast('未设置标签打印机');
    }
  },
  // 安卓打印对接数据处理
  outPrintdataProcess(data, sizeType) {
    console.log('安卓打印对接数据处理 传入的数据', JSON.stringify(data), sizeType);
    let arr = [];
    let printObj = {}

    // 交接班小票
    if (data.type == 1) {
      printObj['shopName'] = data.shop_name;
      printObj['pageTitle'] = '交接班';
  
      printObj['printType'] = '0';
      
      printObj['headerFieldList'] = [];
      printObj['headerTitleList'] = [];
      
      let customList = [];
      customList[0] = [
        `员工：${data.staff || ''}`,
        `开始时间：${data.s_time || ''}`,
        `结束时间：${data.e_time || ''}`,
      ];
      console.log('data.data', data.data);
      for (let i = 0; i < data.data.length; i++) {
        const element = data.data[i];
        let cusData = [];
        cusData.push(`${element.name}`);
        for (let j = 0; j < element.data.length; j++) {
          const d_ele = element.data[j];
          cusData.push(`${d_ele.n}：${d_ele.v}`)
        }
        customList.push(cusData);
      }
      printObj['customList'] = customList;
  
      printObj['mainFieldList'] = [];
      printObj['mainList'] = [];
      printObj['secMainTitle'] = '';
      printObj['secMainFieldList'] = [];
      printObj['secMainList'] = [];
      printObj['thrMainTitle'] = '';
      printObj['thrMainFieldList'] = [];
      printObj['thrMainList'] = [];
      printObj['customContent'] = [];
      printObj['customFont'] = '';
      printObj['footerFieldList'] = [];
      printObj['footerTitleList'] = [];
  
      printObj['bottomContent'] = '';
      printObj['isCutPage'] = store.state.isCutPage; // 是否切纸 0不切 1切纸
  
      arr.push(printObj);
    }

    // 结账单
    if (data.type == 2) {
      printObj['shopName'] = data.shop_name;

      let xiaop = '';
      xiaop = data.isReprint == 1 ? '结账单补打' : '结账单';
      printObj['pageTitle'] = xiaop;

      printObj['printType'] = '0';

      printObj['headerFieldList'] = [];
      printObj['headerTitleList'] = [];
      printObj['headerFieldList'] = ['', "订单单号：", "收银人员：", "收银时间："];
      printObj['headerTitleList'] = ['', data.order_num, (data.staff || ''), data.pay_time || ''];

      printObj["customList"] = [];

      printObj['mainFieldList'] = ["名称", "单价", "数量", "小计"];
      let mainList = [];
      console.log('data.data', data.data);
      // data.data.shift()
      // let pros = data.data.shift();
      // console.log('pros', pros);
      data.data.forEach((element, i) => {
        let food = [];
        food = [`${i + 1}.${element[0]}`, element[1], element[2], element[3]];
        mainList.push(food);
      });
      printObj['mainList'] = mainList;

      printObj['secMainTitle'] = '';
      printObj['secMainFieldList'] = ['订单合计：'];
      printObj['secMainList'] = [data.price];
      if (data.discount_amount && data.discount_amount > 0) {
        if (data.is_member_price == 1) {
          printObj['secMainFieldList'].push(`[会员价优惠]：`);
        } else {
          printObj['secMainFieldList'].push(`[会员折扣:${data.member_type.rate >= 100 ? '' : parseFloat(data.member_type.rate / 10).toFixed(2) + '折'}]：`);
        }
        printObj['secMainList'].push(`-${data.discount_amount.toString()}`);
      }
      if (data.discount_rate_price && data.discount_rate_price > 0) {
        printObj['secMainFieldList'].push(`[打折金额:${data.discount_rate_text}折]：`);
        printObj['secMainList'].push(`-${data.discount_rate_price.toString()}`);
      }
      if (data.wipe_price && data.wipe_price > 0) {
        printObj['secMainFieldList'].push(`[抹零金额]：`);
        printObj['secMainList'].push(`-${data.wipe_price.toString()}`);
      }
      if (data.score_discount && data.score_discount > 0) {
        printObj['secMainFieldList'].push(`[积分抵扣]：`);
        printObj['secMainList'].push(`-${data.score_discount.toString()}`);
      }
      if (data.coupon_price && data.coupon_price > 0) {
        printObj['secMainFieldList'].push(`[优惠券]：`);
        printObj['secMainList'].push(`-${data.coupon_price.toString()}`);
      }

      printObj['thrMainTitle'] = '';
      printObj['thrMainFieldList'] = ['应收：'];
      printObj['thrMainList'] = [data.total_price.toString()];
      if (data.return_money && data.return_money > 0) {
        printObj['thrMainFieldList'].push('退款：');
        printObj['thrMainList'].push(`-${data.return_money.toString()}`);
      }
      printObj['thrMainFieldList'].push(`实收${data.pay_type_text ? '(' + data.pay_type_text + ')' : ''}:`);
      printObj['thrMainList'].push(data.actual_amount.toString());

      printObj['customContent'] = [];
      printObj['customFont'] = '';

      printObj["footerFieldList"] = ["门店地址：", "联系电话："];
      printObj["footerTitleList"] = [data.address, data.tel];

      if (data.pay_type == 6) {
        const phone = data.member.phone && data.member.phone != '' ? data.member.phone.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2') : '';
        const field_ = ["会员姓名：", "会员电话：", "余额："];
        const title_ = [data.member.name || '', phone, data.member.balance || ''];
        printObj["footerFieldList"].unshift(...field_);
        printObj["footerTitleList"].unshift(...title_);
      }

      printObj["bottomContent"] = '';

      printObj['isCutPage'] = store.state.isCutPage; // 是否切纸 0不切 1切纸

      arr.push(printObj);
    }

    // 充值单小票
    if (data.type == 3) {
      printObj['shopName'] = data.shopName;
      printObj['pageTitle'] = '充值单';
      printObj['printType'] = '0';

      printObj['headerFieldList'] = ['', "收银人员：", "收银时间：", "充值单号：", "会员姓名：", "会员电话："];
      printObj['headerTitleList'] = ['', data.shop_admin_name, data.recharge_time, data.recharge_no, data.member_name, data.member_phone];

      printObj['customList'] = [];

      printObj['mainFieldList'] = [];
      printObj['mainList'] = [];

      printObj['secMainTitle'] = '';
      printObj['secMainFieldList'] = ['充值金额：', `赠送金额：`, `充值前余额：`, `充值后余额：`];
      printObj['secMainList'] = [data.amount.toString(), data.give_amount.toString(), data.recharge_before.toString(), data.recharge_after.toString()];

      printObj['thrMainTitle'] = '';
      printObj['thrMainFieldList'] = [`实收${data.pay_type_text ? '(' + data.pay_type_text + ')' : ''}:`];
      printObj['thrMainList'] = [data.amount.toString()];

      printObj['customContent'] = [];
      printObj['customFont'] = '';

      printObj["footerFieldList"] = ["门店地址：", "联系电话："];
      printObj["footerTitleList"] = [data.shop_address, data.shop_phone];

      printObj["bottomContent"] = '';
      printObj['isCutPage'] = store.state.isCutPage; // 是否切纸 0不切 1切纸
      arr.push(printObj);
    }

    // 退货单
    if (data.type == 4) {
      printObj['shopName'] = data.shop_name;

      let xiaop = '';
      xiaop = data.isReprint == 1 ? '退货单补打' : '退货单';
      printObj['pageTitle'] = xiaop;

      printObj['printType'] = '0';

      printObj['headerFieldList'] = [];
      printObj['headerTitleList'] = [];
      printObj['headerFieldList'] = ['', "订单单号：", "收银人员：", "收银时间："];
      printObj['headerTitleList'] = ['', data.order_num, (data.staff || ''), data.pay_time || ''];

      printObj["customList"] = [];

      printObj['mainFieldList'] = ["名称", "单价", "数量", "实退"];
      let mainList = [];
      console.log('data.data', data.data);
      // data.data.shift()
      // let pros = data.data.shift();
      // console.log('pros', pros);
      data.data.forEach((element, i) => {
        let food = [];
        food = [`${i + 1}.${element[0]}`, element[1], element[2], element[3]];
        mainList.push(food);
      });
      printObj['mainList'] = mainList;

      printObj['secMainTitle'] = '';
      printObj['secMainFieldList'] = [];
      printObj['secMainList'] = [];

      printObj['thrMainTitle'] = '';
      printObj['thrMainFieldList'] = [`实退${data.pay_type_text ? '(' + data.pay_type_text + ')' : ''}:`];
      printObj['thrMainList'] = [data.total_price];

      printObj['customContent'] = [];
      printObj['customFont'] = '';

      printObj["footerFieldList"] = ["门店地址：", "联系电话："];
      printObj["footerTitleList"] = [data.address, data.tel];

      printObj["bottomContent"] = '';

      printObj['isCutPage'] = store.state.isCutPage; // 是否切纸 0不切 1切纸

      arr.push(printObj);
    }

    // 标签制作单
    if (data.type == 5) {
      for (let j = 0; j < data.good_list.length; j++) {
        const element = data.good_list[j];
        const p_num = element.print_num || 1;
        for (let i = 0; i < p_num; i++) {
          let tag_obj = {};
          tag_obj['sizeType'] = sizeType || '2'; // 打印尺寸 1=50X40,2=40X30
          tag_obj['printType'] = element.printType || '0'; // 打印类型， 0 为普通 1为预包装
          tag_obj['foodName'] = element.name || '';
          tag_obj['orderPrice'] = '';
          if (element.printType == 0) {
            tag_obj['orderPrice'] = `售价：￥${element.fact_price || '0'}/${element.dw}`;
          } else if (element.printType == 1) {
            tag_obj['mainFieldList'] = ["单价","重量"];
            tag_obj['mainTitleList'] = [`${element.fact_price || '0'}/${element.dw}`, `${element.weight || ''}/${element.dw}`];
            tag_obj['orderPrice'] = `总价：￥${element.total_price || '0'}元`;
          }
          tag_obj['proTime'] = element.produce_time ? `包装日期：${element.produce_time}` : '';
          if (element.printType == 0) {
            tag_obj['periodTime'] = element.quality && element.quality > 0 ? `保质期：${element.quality}天` : '';
          } else if (element.printType == 1) {
            tag_obj['periodTime'] = element.quality && element.quality > 0 ? `保质期;${element.quality}天` : '';
          }
          tag_obj['brValue'] = element.bar_code && element.bar_code.length == 13 ? element.bar_code : '';
          
          arr.push(tag_obj);
        }
      }
    }

    return arr;
  }
}

export default printFunc